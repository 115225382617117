<template>
  <v-dialog
    v-model="dialog"
    width="900"
    persistent
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title class="pb-2">
        Cargar incidente
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-6">
        <v-form ref="form">
          <v-row>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-autocomplete
                v-model="empresa"
                label="Empresa"
                item-text="nombre_corto"
                item-value="id"
                :items="empresas"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
                @change="get_sucursales()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" class="py-0">
              <v-autocomplete
                v-model="sucursal"
                label="Sucursal"
                item-text="nombre"
                item-value="id"
                :items="sucursales"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @change="local = null"
              ></v-autocomplete>
            </v-col>
            <v-col
              v-if="vendedor || cargaInc"
              cols="12" sm="5"
              class="py-0"
            >
              <v-autocomplete
                v-model="local"
                label="Local"
                item-text="nombre"
                item-value="id"
                :items="locales_user.filter(l => l.sucursal == sucursal)"
                :rules="vendedor ? [rules.required] : []"
                :clearable="cargaInc"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col :cols="cargaInc ? 12 : 6" :sm="cargaInc ? 4: 3" class="py-0">
              <v-text-field
                v-if="!cargaInc"
                v-model="$store.state.username"
                label="Contacto"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
              <v-autocomplete
                v-else
                v-model="solicitante"
                label="Solicitante"
                item-text="nombre"
                item-value="usuario"
                :items="solicitantes.filter(s => s.usuario_id != 70 && s.usuario != 'SALTAAUDIT')"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" class="py-0">
              <v-text-field
                v-model.trim="teamviewer"
                label="Team Viewer"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" :md="cargaInc ? 5 : 6" class="py-0">
              <v-text-field
                v-model.trim="asunto"
                label="Asunto"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model="descripcion"
                rows="3"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="pa-0">
              <FileDropzone2
                v-model="archivos"
                :tipos="['.txt', '.png', '.jpg', '.webp', '.doc', '.docx', '.csv', '.xls', '.xlsx', '.pdf']"
                :multiple="true"
                :max_size="25"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-3"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar  
        </v-btn>
        <BtnConfirmar
          nombre="Cargar"
          :texto="`¿Desea cargar el incidente <strong>${asunto}</strong>?`"
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getBase64 } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'
import FileDropzone2 from '../util/FileDropzone2'

export default {
  data () {
    return {
      load: false,
      asunto: '',
      teamviewer: '',
      descripcion: '',
      solicitante: '',
      empresa: null,
      sucursal: null,
      local: null,
      sucursales: [],
      archivos: [],
      rules: {
        required: value => !!value || 'Campo requerido'
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('vendedores/get_locales_user')
    await this.$store.dispatch('incidentes/info_user')
    // si puede cargar incidentes obtengo los solicitantes
    if (this.cargaInc) {
      await this.$store.dispatch('incidentes/get_solicitantes')
    }
    this.$store.state.loading = false
    await this.init_form()
  },
  props: {
    value: Boolean,
    titulo: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('incidentes', ['vendedor', 'favorito', 'cargaInc', 'solicitantes']),
    ...mapState('vendedores', ['locales_user']),
    ...mapState(['empresas']),
    ...mapGetters(['una_empresa']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    FileDropzone2
  },
  watch: {
    async dialog (val) {
      if (val) {
        await new Promise(resolve => setTimeout(resolve, 1))
        this.init_form()
      } else {
        this.limpiar()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        // valido si en algun lado de la descripcion puso adjunto para avisarle por si no adjuntó nada
        const tiene_adjunto = this.descripcion.toLowerCase().indexOf('adjunto') >= 0
        const tiene_archivo = this.descripcion.toLowerCase().indexOf('archivo') >= 0
        if ((tiene_adjunto || tiene_archivo) && this.archivos.length == 0) {
          let ups_me_olvide_el_archivo = false
          await this.$swal.fire({
            icon: 'warning',
            html: `Se encontró la palabra <strong>${tiene_archivo ? 'archivo' : ''} ${tiene_adjunto ? 'adjunto' : ''}</strong> en el cuerpo del mensaje
            pero no se seleccionó ningún archivo.<div style="font-size: 20px; margin-top: 12px"><strong>¿Desea cargar el incidente de todas formas?</strong></div>`,
            confirmButtonText: 'Sí, cargar de todas formas',
            denyButtonText: 'No, seleccionar archivos',
            denyButtonColor: '#3085d6',
            showDenyButton: true,
            focusDeny: true
          })
            .then((res) => {
              // si pone que no o hace click fuera del modal no hace nada
              if (!res.isConfirmed) {
                ups_me_olvide_el_archivo = true
              }
            })
          if (ups_me_olvide_el_archivo) return
        }
        this.load = true
        let archivos = []
        // convierto los archivos en base64
        for (const file of this.archivos) {
          await getBase64(file).then(data => {
            archivos.push({
              file: data.split(',')[1],
              name: file.name
            })
          })
        }
        await this.$store.dispatch('incidentes/nuevo', {
          asunto: this.asunto,
          teamviewer: this.teamviewer,
          descripcion: this.descripcion,
          solicitante: this.cargaInc ? this.solicitante : this.$store.state.username,
          empresa: this.empresa,
          sucursal: this.sucursal,
          local: this.local,
          archivos: archivos
        })
          .then(async (res) => {
            this.dialog = false
            await this.$swal.fire({
              icon: res.errores ? 'warning' : 'success',
              title: res.message,
              html: res.errores
            })
            this.$emit('actualizar')
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.load = false
      }
    },
    async init_form () {
      if (this.titulo) {
        this.asunto = this.titulo
      }
      // si tiene solo 1 empresa la selecciono
      if (this.una_empresa) {
        this.empresa = this.empresas[0].id
        await this.get_sucursales()
        // si tiene solo 1 sucursal la selecciono
        if (this.sucursales.length == 1) {
          this.sucursal = this.sucursales[0].id
          // si es vendedor y tiene un local favorito lo selecciono
          if (this.vendedor && this.favorito) {
            this.local = this.favorito
          }
          else if (this.locales_user.length == 1) {
            this.local = this.locales_user[0].id
          }
        }
      }
    },
    async get_sucursales () {
      this.sucursales = []
      this.sucursal = null
      this.local = null
      if (this.empresa) {
        this.$store.state.loading = true
        await this.$store.dispatch('incidentes/get_sucursales', this.empresa)
          .then((res) => {
            this.sucursales = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    limpiar () {
      this.$refs.form.resetValidation()
      this.asunto = ''
      this.teamviewer = ''
      this.descripcion = ''
      this.solicitante = ''
      this.empresa = null
      this.sucursal = null
      this.local = null
      this.sucursales = []
      this.archivos = []
    }
  }
}
</script>